import ScrollTrigger from "@terwanerik/scrolltrigger";
import $ from "jquery";
import "slick-carousel";

const trigger = new ScrollTrigger({
  trigger: {
    once: true,
    offset: {
      element: {
        x: 0,
        y: (trigger, rect, direction) => {
          return 0.5;
        },
      },
    },
  },
}); // When not using npm, create a new instance with 'new ScrollTrigger.default()'
trigger.add("[data-trigger]");

const trigger80 = new ScrollTrigger({
  trigger: {
    once: true,
    offset: {
      element: {
        x: 0,
        y: (trigger, rect, direction) => {
          return 0.8;
        },
      },
    },
  },
}); // When not using npm, create a new instance with 'new ScrollTrigger.default()'
if (window.innerWidth >= 600) {
  trigger80.add("[data-trigger-80]");
}
window.onload = function () {
  $("body").animate({ opacity: "1" }, 2000);
};

// $(".insta-gallery__wrapper").slick({
//   slidesToshow: 1,
//   slidesToSCroll: 1,
//   arrows: false,
// });

// $(".insta-gallery__prev-button").on("click touch", function () {
//   $(".insta-gallery__wrapper").slick("slickPrev");
// });

// $(".insta-gallery__next-button").on("click touch", function () {
//   $(".insta-gallery__wrapper").slick("slickNext");
// });
